import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"; //@material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import getNepaliNumber from "get-nepali-number";
import React, { Component } from "react";
import Card from "../../../common/Card/Card.jsx";
import CardBody from "../../../common/Card/CardBody.jsx";
import CardHeader from "../../../common/Card/CardHeader.jsx";
import GridContainer from "../../../common/Grid/GridContainer.jsx";
import GridItem from "../../../common/Grid/GridItem.jsx";
import ReactToPrint from "../../../common/frequent/print/ReactToPrint";
import Fab from "@material-ui/core/Fab";
import PrintIcon from "@material-ui/icons/Print";
import FamilyScroll from "../../../common/frequent/dialog/FamilyScroll";

class DetailAnalysisTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beforePrint: false,
      component: null,
      argumentTobePassed: [],
      open: false,
    };
    this.myRef = React.createRef();
  }

  handleOpen = (argumentTobePassed) => (event) => {
    this.setState({
      argumentTobePassed,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      argumentTobePassed: [],
      open: false,
    });
  };

  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState({ beforePrint: true, component: this.myRef }, () => {
      return this.state.component;
    });
  };

  render() {
    const { chart, classes, isPrintIcon, printHandle } = this.props;
    const style =
      chart.functions !== "null"
        ? { color: "blue", textDecoration: "underline" }
        : {};
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Grid container justify="space-between" alignItems="flex-start">
                <Grid item>
                  <Typography variant="h6" className={classes.cardTitleWhite}>
                    {chart.title}
                  </Typography>
                </Grid>

                {chart.functions !== "null" && (
                  <FamilyScroll
                    handleClose={this.handleClose}
                    open={this.state.open}
                    args={this.state.argumentTobePassed}
                    functions={chart.functions}
                  />
                )}

                <Grid item>
                  {
                    <ReactToPrint
                      style={{ marginTop: 200 }}
                      onBeforePrint={this.handleBeforePrint}
                      onAfterPrint={this.handleAfterPrint}
                      trigger={() => (
                        <Fab
                          variant="extended"
                          aria-label="Delete"
                          className={classes.fab}
                        >
                          <PrintIcon className={classes.printIcon} />
                        </Fab>
                      )}
                      data={this.state.component}
                      content={this.getContent}
                    />
                  }
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <div ref={this.myRef}>
                {/* Table here */}
                {this.state.beforePrint && (
                  <Typography variant="h6" className={classes.title}>
                    {chart.title}
                  </Typography>
                )}
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {chart.reportTitle.map((header) => (
                        <TableCell>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chart.single &&
                      chart.single.map((m, id) => (
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">
                            {m.label}
                          </TableCell>
                          <TableCell
                            style={style}
                            align="left"
                            onClick={
                              chart.functions !== "null" &&
                              this.handleOpen(chart.female[id].args)
                            }
                          >
                            {getNepaliNumber(chart.female[id].value)}
                          </TableCell>

                          <TableCell
                            style={style}
                            align="left"
                            onClick={
                              chart.functions !== "null" &&
                              this.handleOpen(chart.male[id].args)
                            }
                          >
                            {getNepaliNumber(chart.male[id].value)}
                          </TableCell>

                          <TableCell
                            style={style}
                            align="left"
                            onClick={
                              chart.functions !== "null" &&
                              this.handleOpen(chart.third[id].args)
                            }
                          >
                            {getNepaliNumber(chart.third[id].value)}
                          </TableCell>

                          <TableCell
                            style={style}
                            align="left"
                            onClick={
                              chart.functions !== "null" &&
                              this.handleOpen(m.args)
                            }
                          >
                            {getNepaliNumber(m.value)}
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow />
                    <TableRow>
                      <TableCell component="th" scope="row">
                        जम्मा जनसंख्या
                      </TableCell>
                      <TableCell align="left">
                        {getNepaliNumber(
                          chart.female.reduce((a, c) => (a += c.value), 0)
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {getNepaliNumber(
                          chart.male.reduce((a, c) => (a += c.value), 0)
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {getNepaliNumber(
                          chart.third.reduce((a, c) => (a += c.value), 0)
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {getNepaliNumber(
                          chart.single.reduce((a, c) => (a += c.value), 0)
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  white: {
    color: "#FFF",
  },
});

export default withStyles(styles)(DetailAnalysisTable);
