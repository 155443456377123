import {
  START_LOADER,
  STOP_LOADER,
  START_SNACK,
  CLOSE_SNACK
} from "../actions/types";
import _ from "lodash";
const initialState = {
  breadCrumb: [],
  fileList: [],
  loading: false,
  showAccount: false,
  snack: {
    open: false,
    variant: "",
    message: "",
    vertical: "bottom",
    horizontal: "right",
    autoHideDuration: 6000
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_SNACK:
      return {
        ...state,
        snack: _.merge(
          {
            vertical: "bottom",
            horizontal: "right",
            autoHideDuration: 6000
          },
          action.payload
        )
      };

    case CLOSE_SNACK:
      return {
        ...state,
        ...initialState
      };

    case START_LOADER:
      return {
        ...state,
        loading: true
      };

    case STOP_LOADER:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
