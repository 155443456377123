import React, { Component } from "react";
import Axios from "axios";
import { Grid, Typography, Paper } from "@material-ui/core";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { selectedLanguage } from "../../../variable/global";
import { Divider, Table } from "antd";
import { withStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Fab from "@material-ui/core/Fab";
import ReactToPrint from "../../common/frequent/print/ReactToPrint";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";

const isNumber = (value) =>
  typeof value === "number" && value === value && Number.isFinite(value);
const style = {
  p: {
    margin: 0,
    padding: 0,
    fontWeight: "800",
  },
};
export default class PreviewForm extends Component {
  state = {
    data: {},
    mapValue: {},
    mapLabel: {},
    header: "",
    beforePrint: false,
    component: null,
  };
  componentDidMount() {
    const { id, category } = this.props.match.params;
    Axios.get(`/api/institute/${category}/read/${id}`).then((res) => {
      this.setState({
        data: res.data.data,
        mapValue: res.data.mapValue,
        mapLabel: res.data.mapLabel,
        header: res.data.header,
      });
    });
  }

  printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  }

  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState(
      { beforePrint: true, component: this.componentRef },
      () => {
        return this.state.component;
      }
    );
  };

  pageNotFound = () => <p>Not found Id</p>;
  render() {
    const { data, mapValue, mapLabel, header } = this.state;
    const keys = Object.keys(data);
    if (!data) return this.pageNotFound();
    return (
      <Paper elevation={3} style={{ padding: "10px 10px 10px 10px" }}>
        <Grid container>
          <Grid style={{ marginLeft: "auto" }}>
            <ReactToPrint
              style={{ marginTop: 100 }}
              onBeforePrint={this.handleBeforePrint}
              onAfterPrint={this.handleAfterPrint}
              trigger={() => (
                <Fab
                  variant="extended"
                  aria-label="Delete"
                  // className={classes.fab}
                  style={{
                    backgroundColor: "#03a9f4",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#03a9f4",
                    },
                  }}
                >
                  <PrintIcon />
                </Fab>
              )}
              data={this.state.component}
              content={this.getContent}
            />
            &nbsp;&nbsp;
            <Fab
              variant="extended"
              aria-label="Delete"
              onClick={this.printDocument}
              // className={classes.fab}
              style={{
                backgroundColor: "#03a9f4",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#03a9f4",
                },
              }}
            >
              <PictureAsPdfIcon />
            </Fab>
          </Grid>
          {/* Title Here */}
          {/* image Here */}
          <Grid
            container
            spacing={1}
            ref={(el) => (this.componentRef = el)}
            id="divToPrint"
          >
            <Divider>
              <b style={{ fontSize: 25 }}>{header || "heading"}</b>
            </Divider>
            {keys.map((key) => this.renderGeneric(data, key))}
          </Grid>
        </Grid>
      </Paper>
    );
  }
  renderGeneric = (on, key) => {
    const value = on[key];
    if (value === undefined || value === null) return null;
    if (Array.isArray(value) && value.length > 0) {
      if (typeof value[0] === "string")
        return this.renderCommaSeparated(key, value);
      else if (typeof value[0] === "object")
        return this.renderTable(value, key);
      else return null;
    } else if (typeof value === "object") {
      const { mapLabel } = this.state;
      if (mapLabel[key]) return this.renderBoxObject(value, key); //if key is exist in mapLabel then render the box else just recursive
      return Object.keys(value).map((k) => this.renderGeneric(value, k));
    } else if (typeof value === "boolean") {
      return this.renderBoolean(key, value);
    } else if (typeof value === "string" || isNumber(value)) {
      return this.renderKey(key, value);
    } else return null;
  };

  notPrintIF = (key) => {
    return key === "_id" || !key;
  };
  renderTable = (on, key) => {
    const { mapLabel } = this.state;
    const keys = Object.keys(on[0]);
    var columns = [];
    keys.forEach((each) => {
      if (this.notPrintIF(each)) return;
      else
        columns.push({
          title: (
            <p style={{ fontSize: 14, fontWeight: 600 }}>
              {mapLabel[each] || each}
            </p>
          ),
          dataIndex: each,
          key: each,
          render: (text) => <p>{text}</p>,
        });
    });
    var data = [];
    on.forEach((each) => {
      if (this.notPrintIF(each)) return;
      let body = {};
      keys.forEach((one) => (body[one] = this.getValue(one, each[one])));
      data.push(body);
    });
    console.log("data", data);
    return (
      <Grid container style={{ margin: "20px 0px" }}>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          bordered
          title={() => (
            <p style={{ fontSize: 17, fontWeight: 600 }}>{mapLabel[key]}</p>
          )}
        />
      </Grid>
    );
  };

  renderBoxObject = (on, key) => {
    const { mapLabel } = this.state;
    return (
      <Grid
        container
        style={{
          padding: 5,
          margin: "5px 0px",
        }}
      >
        <Divider>
          <b style={{ fontSize: 20 }}>{mapLabel[key] || key}</b>
        </Divider>
        <Grid container>
          {Object.keys(on).map((k) => this.renderGeneric(on, k))}
        </Grid>
        <Divider>X</Divider>
      </Grid>
    );
  };

  renderHeading = (text, extraText = "") => {
    const { mapLabel } = this.state;

    return (
      <Typography variant="h6" style={style.p}>
        {(mapLabel[text] || text) + extraText}
      </Typography>
    );
    // <p style={style.p}>{(mapLabel[text] || text) + extraText} </p>;
  };
  getValue = (key, value) => {
    const { mapValue } = this.state;
    var text = value || "N/A";
    if (mapValue[key]) {
      const foundOne = mapValue[key].find((each) => each.value === value);
      if (foundOne) text = foundOne[selectedLanguage];
    }
    return text;
  };
  renderValue = (key, value) => {
    return (
      <Typography variant="h6" style={{ marginLeft: 8 }}>
        {isNaN(value)
          ? this.getValue(key, value)
          : getNepaliNumber(this.getValue(key, value))}
        {/* {getNepaliNumber(this.getValue(key, value))} */}
      </Typography>
    );
  };
  renderCommaSeparated = (key, value) => {
    return (
      <Grid item xs={12} md={12} lg={12} style={{ display: "flex" }}>
        {this.renderHeading(key, ":")}
        <Typography variant="h6" style={{ marginLeft: 8 }}>
          {value.map((each) => this.getValue(key, each)).join(", ")}
        </Typography>
      </Grid>
    );
  };

  renderKey = (key, value) => {
    return (
      <Grid item xs={12} md={6} lg={4} style={{ display: "flex" }}>
        {this.renderHeading(key, ":")} {this.renderValue(key, value)}
      </Grid>
    );
  };

  renderBoolean = (key, value) => {
    return (
      <Grid item xs={12} md={6} lg={4} style={{ display: "flex" }}>
        {this.renderHeading(key, ":")}
        <Typography variant="h6" style={{ marginLeft: 5 }}>
          {value ? " छ" : " छैन"}
        </Typography>
      </Grid>
    );
  };

  renderArray = () => {
    const { data, mapValue, mapLabel } = this.state;
  };

  renderObject = () => {
    const { data, mapValue, mapLabel } = this.state;
  };
}
