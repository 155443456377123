import { Grid, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Typography,
  Upload,
  Tooltip,
  Table
} from "antd";
import Axios from "axios";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInstitutionSurvey } from "../../../actions/instituteAction";
import {
  add,
  remove,
  address,
  name,
  wardQuestion,
  photo,
  healthTitle,
  healthInfo,
  healthFacility,
  healthFacilityTitle,
  healthLabel,
  instituteTitle,
  healthInstituteType,
  shayaCountQn,
  vaccineTitle,
  vaccineQn,
  vaccineList,
  fiscalYear
} from "../../../variable/institute";
import isEmpty from "../../../utils/validation/is-empty";
import { no, selectedLanguage, ward, yes } from "../../../variable/global";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import CodeInput from "../../common/codeInput/";
// import ReactCodeInput from "react-verification-code-input";
import { getHealthPost } from "./api";
const { Option } = Select;
const { TextArea } = Input;

class Health extends Component {
  random = (low, high) => {
    return Math.random() * (high - low) + low;
  };
  state = {
    // image upload state
    uniqueIdentifier:
      this.props.match.params.category +
      "_" +
      this.random(10000, 99999) +
      this.random(1000, 9999),
    previewVisible: false,
    previewImage: "",
    loaded: 0,
    name: "",
    ward: "",
    address: "",
    fileList: {
      healthImage: []
    },
    maxHealthsIndex: [1],
    healths: [],
    shayaCount: "",
    maxVaccinesIndex: [1],
    fiscalYear: [],
    vaccines: [],
    maxYearIndex: 1,
    healthType: "",
    healthFacility: []
  };

  // image functions uplocad
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  // imageUpload
  handleChangeFile = (name, value, imageName) => {
    //name ma house and value ma list of filelist
    console.log("CF", value);
    this.setState(
      prevState => ({
        ...prevState,
        fileList: {
          ...prevState.fileList,
          [name]: {
            image: value.fileList,
            fileName: prevState.uniqueIdentifier + "_" + imageName + ".jpg"
          }
        }
      }),
      () => {
        if (value.fileList.length > 0) this.handleUpload(name, imageName);
        if (value.fileList.length === 0) this.removeUpload(name, imageName);
      }
    );
  };

  // upload image
  handleUpload = (name, imageName) => {
    const fileList = this.state.fileList[name];
    const data = new FormData();
    const imageObject = fileList.image[0].originFileObj;

    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";
    data.append("file", imageObject, fileName);

    Axios.post("/api/imageUpload/upload", data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
        });
      }
    })
      .then(res => {
        // then print response status
        message.success("upload successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  // remove uploaded image
  removeUpload = imageName => {
    let fileName = this.state.uniqueIdentifier + "_" + imageName + ".jpg";

    Axios.get(`/api/imageUpload/delete/${fileName}`)
      .then(res => {
        // then print response status
        message.success("deleted successfully.");
        console.log(res.statusText);
      })
      .catch(err => message.error("upload failed."));
  };

  componentDidMount() {
    let hospitalId = this.props.match.params.id;
    if (hospitalId) {
      getHealthPost(hospitalId).then(res =>
        this.setState(
          {
            name: res.data.name,
            ward: res.data.ward,
            address: res.data.address,
            healthType: res.data.healthType,
            maxHealthsIndex: res.data.maxHealthIndex,
            maxVaccinesIndex: res.data.maxVaccineIndex,
            maxYearIndex: res.data.fiscalYear && res.data.fiscalYear.length,
            healthFacility: res.data.healthFacility,
            fiscalYear: res.data.fiscalYear,
            shayaCount: res.data.shayaCount,
            healths: res.data.health,
            vaccines: res.data.vaccine,
            // image
            fileList: {
              school: {
                fileName: res.data.uniqueIdentifier + "_school.jpg",
                image: [
                  {
                    uid: "-1",
                    name: `${res.data.uniqueIdentifier}_school.jpg`,
                    status: "done",
                    url: `/api/imageUpload/image/${res.data.uniqueIdentifier}_school.jpg`
                  }
                ]
              }
            }
          },
          () => console.log("in cmds", this.state.name)
        )
      );
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const newSchool = {
      name: this.state.name,
      address: this.state.address,
      uniqueIdentifier: this.state.uniqueIdentifier,
      ward: this.state.ward,
      shayaCount: this.state.shayaCount,
      healthType: this.state.healthType,
      healthFacility: this.state.healthFacility,
      healths: this.state.healths,
      vaccines: this.state.vaccines,
      fiscalYear: this.state.fiscalYear
    };
    if (this.props.isUpdate) {
      newSchool.id = this.props.match.params.id;
      this.props.addInstitutionSurvey(
        "healthPost",
        newSchool,
        this.props.history,
        this.props.match.params.category
      );
    } else
      this.props.addInstitutionSurvey(
        "healthPost",
        newSchool,
        this.props.history,
        this.props.match.params.category
      );
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSelectChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  addDhikka = (stateName, yearIndex) => () => {
    // console.log("stateName", stateName);
    // var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
    // console.log("max", maxIndex);
    // this.setState(prevState => ({
    //   [maxIndex]: prevState[maxIndex] + 1
    // }));

    var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
    console.log("yearIndex", yearIndex);

    if (yearIndex === undefined) {
      this.setState(prevState => {
        // var students = prevState.students;
        // students.splice(yearIndex + 1, 0, []);
        return {
          [maxIndex]: prevState[maxIndex] + 1,
          maxHealthsIndex: [...(prevState.maxHealthsIndex + 1)],
          maxVaccinesIndex: [...(prevState.maxVaccinesIndex + 1)]
        };
      });
    } else {
      this.setState(prevState => {
        const indexMax = prevState[maxIndex] || [];
        console.log("before indexMax", indexMax);

        indexMax[yearIndex] = parseInt(parseInt(indexMax[yearIndex] || 0) + 1);
        console.log("after indexMax", indexMax);

        return { [maxIndex]: indexMax };
      });
    }
  };

  handleMultipleInput = (stateName, year, index, name) => value => {
    // const { value, name } = evt.target;
    this.setState(prevState => {
      const schoolPeople = prevState[stateName];
      if (schoolPeople[year] === undefined) schoolPeople[year] = [];
      if (schoolPeople[year][index] === undefined)
        schoolPeople[year][index] = [];
      const data = { ...schoolPeople[year][index], [name]: value };
      schoolPeople[year].splice(index, 1, data);
      return { [stateName]: schoolPeople };
    });
  };

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  removePeoplRow = (stateName, year, index) => () => {
    this.setState(prevState => {
      const schoolPeople = prevState[stateName];
      if (schoolPeople[year] === undefined) schoolPeople[year] = [];
      if (schoolPeople[year][index] === undefined)
        schoolPeople[year][index] = [];
      schoolPeople[year].splice(index, 1);
      // students.splice(index, 1);
      var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
      const indexMax = prevState[maxIndex] || [];
      indexMax[year] = parseInt(parseInt(indexMax[year] || 1) - 1);
      return { [stateName]: schoolPeople, [maxIndex]: indexMax };
    });
  };

  getRemovedPeopleData = (stateName, year) => {
    var schoolPeople = this.state[stateName];
    // if (schoolPeople[year] === undefined) schoolPeople[year] = [];
    schoolPeople.splice(year, 1);
    return schoolPeople;
  };

  getRemovedPeopleMaxIndex = (stateName, year) => {
    var maxIndex = `max${this.capitalizeFirstLetter(stateName)}Index`;
    var indexMax = this.state[maxIndex] || [];
    indexMax.splice(year, 1);
    return indexMax;
  };

  removeMainDhikka = year => async () => {
    const healths = this.getRemovedPeopleData("healths", year);
    const maxHealthsIndex = this.getRemovedPeopleMaxIndex("healths", year);
    const vaccines = this.getRemovedPeopleData("vaccines", year);
    const maxVaccinesIndex = this.getRemovedPeopleMaxIndex("vaccines", year);

    const fiscalYear = this.state.fiscalYear;
    const maxYearIndex = this.state.maxYearIndex;
    fiscalYear.splice(year, 1);

    this.setState({
      maxHealthsIndex,
      healths,
      vaccines,
      maxVaccinesIndex,
      fiscalYear,
      maxYearIndex: maxYearIndex - 1
    });
  };

  handleFiscalYearChange = year => data => {
    this.setState(prevState => {
      let fiscalYear = prevState.fiscalYear || [];
      fiscalYear[year] = data;
      return { fiscalYear };
    });
  };

  getHealthUI = yearIndex => {
    const { maxHealthsIndex, healths } = this.state;
    const columns = [
      {
        title: "S.N",
        dataIndex: "sn",
        key: "sn",
        render: index => <p>{getNepaliNumber(index + 1)}</p>
      },
      {
        title: "चिकित्सक र अन्य कर्मचारी",
        dataIndex: "sn",
        key: "healthInfo",
        render: each => (
          <Select
            placeholder="------------"
            name="healthInfo"
            value={
              healths &&
              healths[yearIndex] &&
              healths[yearIndex][each] &&
              healths[yearIndex][each].healthInfo
            }
            onChange={this.handleMultipleInput(
              "healths",
              yearIndex,
              each,
              "healthInfo"
            )}
            style={{ minWidth: 100 }}
          >
            {healthInfo.map(info => (
              <Option value={info.value}>{info[selectedLanguage]}</Option>
            ))}
          </Select>
        )
      },
      {
        title: "ढरबन्दी",
        dataIndex: "sn",
        key: "darbandi",
        render: each => (
          <InputNumber
            name="darbandi"
            type="number"
            min={0}
            value={
              healths &&
              healths[yearIndex] &&
              healths[yearIndex][each] &&
              healths[yearIndex][each].darbandi
            }
            onChange={this.handleMultipleInput(
              "healths",
              yearIndex,
              each,
              "darbandi"
            )}
            margin="normal"
            variant="outlined"
          />
        )
      },
      {
        title: "उपलब्धा संख्या",
        dataIndex: "sn",
        key: "count",
        render: each => (
          <InputNumber
            name="count"
            type="number"
            min={0}
            value={
              healths &&
              healths[yearIndex] &&
              healths[yearIndex][each] &&
              healths[yearIndex][each].count
            }
            onChange={this.handleMultipleInput(
              "healths",
              yearIndex,
              each,
              "count"
            )}
            margin="normal"
            variant="outlined"
          />
        )
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "sn",
        render: index => (
          <Button
            type="dashed"
            onClick={this.removePeoplRow("healths", yearIndex, index)}
          >
            <Icon style={{ color: "red" }} type="minus" />
            {remove[selectedLanguage]}
          </Button>
        )
      }
    ];

    const dataSource = [...Array(maxHealthsIndex[yearIndex]).keys()].map(
      sn => ({
        sn,
        level: ""
      })
    );

    return (
      <Grid xs={12}>
        <Form.Item label={fiscalYear[selectedLanguage]}>
          <CodeInput
            fields={4}
            type="number"
            name="fiscalYear"
            values={(() => {
              let data =
                this.state.fiscalYear &&
                this.state.fiscalYear[yearIndex] &&
                this.state.fiscalYear[yearIndex].split("");
              data = data || [0, 0, 0, 0];
              return data;
            })()}
            onChange={this.handleFiscalYearChange(yearIndex)}
          />
        </Form.Item>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          title={() => (
            <Button
              type="dashed"
              onClick={this.addDhikka("healths", yearIndex)}
            >
              <Icon type="plus" />
              {add[selectedLanguage]}
            </Button>
          )}
        />
      </Grid>
    );
  };

  getVaccineUI = yearIndex => {
    const { maxVaccinesIndex, vaccines } = this.state;
    const columns = [
      {
        title: "S.N",
        dataIndex: "sn",
        key: "sn",
        render: index => <p>{getNepaliNumber(index + 1)}</p>
      },
      {
        title: vaccineQn[selectedLanguage],
        dataIndex: "sn",
        key: "vaccineInfo",
        render: each => (
          <Select
            placeholder="------------"
            name="vaccineInfo"
            value={
              vaccines &&
              vaccines[yearIndex] &&
              vaccines[yearIndex][each] &&
              vaccines[yearIndex][each].vaccineInfo
            }
            onChange={this.handleMultipleInput(
              "vaccines",
              yearIndex,
              each,
              "vaccineInfo"
            )}
            style={{ minWidth: 100 }}
          >
            {vaccineList.map(list => (
              <Option value={list.value}>{list[selectedLanguage]}</Option>
            ))}
          </Select>
        )
      },
      {
        title: "संख्या",
        dataIndex: "sn",
        key: "count",
        render: each => (
          <InputNumber
            name="count"
            type="number"
            min={0}
            value={
              vaccines &&
              vaccines[yearIndex] &&
              vaccines[yearIndex][each] &&
              vaccines[yearIndex][each].count
            }
            onChange={this.handleMultipleInput(
              "vaccines",
              yearIndex,
              each,
              "count"
            )}
            margin="normal"
            variant="outlined"
          />
        )
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "sn",
        render: index => (
          <Button
            type="dashed"
            onClick={this.removePeoplRow("vaccines", yearIndex, index)}
          >
            <Icon style={{ color: "red" }} type="minus" />
            {remove[selectedLanguage]}
          </Button>
        )
      }
    ];

    const dataSource = [...Array(maxVaccinesIndex[yearIndex]).keys()].map(
      sn => ({
        sn,
        level: ""
      })
    );

    return (
      <Grid xs={12}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          title={() => (
            <Button
              type="dashed"
              onClick={this.addDhikka("vaccines", yearIndex)}
            >
              <Icon type="plus" />
              {add[selectedLanguage]}
            </Button>
          )}
        />
      </Grid>
    );
  };

  getYearUI = () => {
    const { maxYearIndex } = this.state;
    return [...Array(maxYearIndex).keys()].map(yearIndex => (
      <Grid>
        <Divider orientation="left">{healthTitle[selectedLanguage]}</Divider>
        {this.getHealthUI(yearIndex)}
        <Divider orientation="left">{vaccineTitle[selectedLanguage]}</Divider>
        {this.getVaccineUI(yearIndex)}

        {parseInt(yearIndex) === maxYearIndex - 1 ? (
          <Grid container spacing={2}>
            <Button
              type="dashed"
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              onClick={this.addDhikka("year")}
            >
              <Icon type="plus" />
              {add[selectedLanguage]}
            </Button>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Button
              style={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              type="dashed"
              onClick={this.removeMainDhikka(yearIndex)}
            >
              <Icon style={{ color: "red" }} type="minus" />
              {remove[selectedLanguage]}
            </Button>
          </Grid>
        )}
      </Grid>
    ));
  };

  render() {
    const { classes, title } = this.props;
    const { previewVisible, previewImage } = this.state;
    const fileList = this.state.fileList;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5">{title}</Typography>
        <Form {...formItemLayout} onSubmit={this.onSubmit}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid xs={6}>
              <Form.Item label={name[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("name")}
                  value={this.state.name}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
            <Grid xs={6}>
              <Form.Item label={wardQuestion[selectedLanguage]}>
                <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("ward", val)}
                  value={this.state.ward}
                  disabled={this.state.isView}
                >
                  {ward.map(woda => (
                    <Option value={woda.value}>{woda.ward}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>
            <Grid xs={6}>
              <Form.Item label={address[selectedLanguage]}>
                <Input
                  onChange={this.handleInputChange("address")}
                  value={this.state.address}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>
            <Grid xs={6}>
              <Form.Item label={instituteTitle[selectedLanguage]}>
                <Select
                  placeholder="------------"
                  onChange={val => this.handleSelectChange("healthType", val)}
                  value={this.state.healthType}
                  disabled={this.state.isView}
                >
                  {healthInstituteType.map(type => (
                    <Option value={type.value}>{type[selectedLanguage]}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>

            <Grid xs={6}>
              <Form.Item label={shayaCountQn[selectedLanguage]}>
                <Input
                  type="number"
                  min={0}
                  onChange={this.handleInputChange("shayaCount")}
                  value={this.state.shayaCount}
                  disabled={this.state.isView}
                />
              </Form.Item>
            </Grid>

            <Grid xs={6}>
              <Form.Item label={photo[selectedLanguage]}>
                <Fragment>
                  {!this.state.isView ? (
                    <Upload
                      name="file"
                      // beforeUpload={this.props.beforeUpload}
                      multiple={false}
                      // action="/api/imageUpload/upload"
                      accept="image/*"
                      listType="picture-card"
                      fileList={
                        fileList.healthImage && fileList.healthImage.image
                      }
                      onPreview={this.handlePreview}
                      onChange={fileList =>
                        this.handleChangeFile("healthImage", fileList, "school")
                      }
                      disabled={this.state.isView}
                    >
                      {fileList &&
                      fileList.healthImage &&
                      fileList.healthImage.image &&
                      fileList.healthImage.image.length === 1
                        ? null
                        : uploadButton}
                    </Upload>
                  ) : (
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  )}
                </Fragment>
              </Form.Item>
            </Grid>

            <Grid xs={12}>{this.getYearUI()}</Grid>

            <Divider orientation="left">
              {healthFacilityTitle[selectedLanguage]}
            </Divider>
            {/* {window.location.href.split("/").pop() === "healthPost" && ( */}
            <Grid xs={6}>
              <Form.Item label={healthLabel[selectedLanguage]}>
                <Select
                  mode="multiple"
                  name="healthFacility"
                  // style={{ width: "50%" }}
                  placeholder="Please select"
                  value={this.state.healthFacility}
                  onChange={val =>
                    this.handleSelectChange("healthFacility", val)
                  }
                >
                  {healthFacility.map(facility => (
                    <Option value={facility.value}>
                      {facility[selectedLanguage]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid>
            {/* )} */}

            <Divider />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.isView}
              >
                {this.props.isUpdate ? "update" : add[selectedLanguage]}
              </Button>
            </Form.Item>
          </Grid>
        </Form>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const mapStateToProps = state => ({});

export default withStyles(styles)(
  connect(mapStateToProps, { addInstitutionSurvey })(withRouter(Health))
);
