import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

// import fuse from "../fuseSettings";
function FusePageCardedHeader(props) {
	const theme = createMuiTheme({
		palette: {
			common: {
				black: "#000",
				white: "#fff"
			},
			type: "dark",
			primary: {
				"50": "#ECECEE",
				"100": "#C5C6CB",
				"200": "#9EA1A9",
				"300": "#7D818C",
				"400": "#5C616F",
				"500": "#3C4252",
				"600": "#353A48",
				"700": "#2D323E",
				"800": "#262933",
				"900": "#1E2129",
				A100: "#C5C6CB",
				A200: "#9EA1A9",
				A400: "#5C616F",
				A700: "#2D323E",
				main: "#3C4252",
				light: "#7D818C",
				dark: "#2D323E",
				contrastText: "#fff"
			},

			error: {
				"50": "#ffebee",
				"100": "#ffcdd2",
				"200": "#ef9a9a",
				"300": "#e57373",
				"400": "#ef5350",
				"500": "#f44336",
				"600": "#e53935",
				"700": "#d32f2f",
				"800": "#c62828",
				"900": "#b71c1c",
				A100: "#ff8a80",
				A200: "#ff5252",
				A400: "#ff1744",
				A700: "#d50000",
				main: "#f44336",
				light: "#e57373",
				dark: "#d32f2f",
				contrastText: "#fff"
			},
			grey: {
				"50": "#fafafa",
				"100": "#f5f5f5",
				"200": "#eeeeee",
				"300": "#e0e0e0",
				"400": "#bdbdbd",
				"500": "#9e9e9e",
				"600": "#757575",
				"700": "#616161",
				"800": "#424242",
				"900": "#212121",
				A100: "#d5d5d5",
				A200: "#aaaaaa",
				A400: "#303030",
				A700: "#616161"
			},
			contrastThreshold: 3,
			tonalOffset: 0.2,
			text: {
				primary: "#fff",
				secondary: "rgba(255, 255, 255, 0.7)",
				disabled: "rgba(255, 255, 255, 0.5)",
				hint: "rgba(255, 255, 255, 0.5)",
				icon: "rgba(255, 255, 255, 0.5)"
			},
			divider: "rgba(255, 255, 255, 0.12)",
			background: {
				paper: "#424242",
				default: "#303030"
			},
			action: {
				active: "#fff",
				hover: "rgba(255, 255, 255, 0.1)",
				hoverOpacity: 0.1,
				selected: "rgba(255, 255, 255, 0.2)",
				disabled: "rgba(255, 255, 255, 0.3)",
				disabledBackground: "rgba(255, 255, 255, 0.12)"
			}
		}
	});
	return (
		<div className={props.classes.header}>
			{props.header && (
				<ThemeProvider theme={theme}>{props.header}</ThemeProvider>
			)}
		</div>
	);
}

export default FusePageCardedHeader;
