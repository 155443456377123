import React from "react";
import { Paper, Input, Icon, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import { createMuiTheme } from "@material-ui/core/styles";
import { FuseAnimate } from "./component/";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { Button } from "antd";
function ProductsHeader(props) {
  const theme = createMuiTheme({
    palette: {
      common: {
        black: "#000",
        white: "#fff",
      },
      type: "light",
      primary: {
        "50": "#ECECEE",
        "100": "#C5C6CB",
        "200": "#9EA1A9",
        "300": "#7D818C",
        "400": "#5C616F",
        "500": "#3C4252",
        "600": "#353A48",
        "700": "#2D323E",
        "800": "#262933",
        "900": "#1E2129",
        A100: "#C5C6CB",
        A200: "#9EA1A9",
        A400: "#5C616F",
        A700: "#2D323E",
        main: "#3C4252",
        light: "#7D818C",
        dark: "#2D323E",
        contrastText: "#fff",
      },

      error: {
        "50": "#ffebee",
        "100": "#ffcdd2",
        "200": "#ef9a9a",
        "300": "#e57373",
        "400": "#ef5350",
        "500": "#f44336",
        "600": "#e53935",
        "700": "#d32f2f",
        "800": "#c62828",
        "900": "#b71c1c",
        A100: "#ff8a80",
        A200: "#ff5252",
        A400: "#ff1744",
        A700: "#d50000",
        main: "#f44336",
        light: "#e57373",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      grey: {
        "50": "#fafafa",
        "100": "#f5f5f5",
        "200": "#eeeeee",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#616161",
        "800": "#424242",
        "900": "#212121",
        A100: "#d5d5d5",
        A200: "#aaaaaa",
        A400: "#303030",
        A700: "#616161",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
      divider: "rgba(0, 0, 0, 0.12)",
      background: {
        paper: "#fff",
        default: "#fafafa",
      },
      action: {
        active: "rgba(0, 0, 0, 0.54)",
        hover: "rgba(0, 0, 0, 0.08)",
        hoverOpacity: 0.08,
        selected: "rgba(0, 0, 0, 0.14)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
      },
    },
  });
  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <FuseAnimate animation="transition.expandIn" delay={300}>
          <HomeIcon className="text-32 mr-0 sm:mr-12"></HomeIcon>
        </FuseAnimate>
        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
          <Typography className="hidden sm:flex text-white" variant="h6">
            {props.header || "शीर्षकविहिन"}
          </Typography>
        </FuseAnimate>
      </div>

      <div className="flex flex-1 items-center justify-center px-12">
        <ThemeProvider theme={theme}>
          <FuseAnimate animation="transition.slideDownIn" delay={300}>
            <Paper
              className="flex items-center w-4/6 max-w-512 px-8 py-4 rounded-12"
              elevation={1}
            >
              <SearchIcon className="mr-8" color="action"></SearchIcon>

              <Input
                placeholder="खोज्नुहोस्"
                className="flex flex-1"
                disableUnderline
                fullWidth
                value={props.searchText}
                inputProps={{
                  "aria-label": "Search",
                }}
                onChange={props.handleSearchText}
              />
            </Paper>
          </FuseAnimate>
        </ThemeProvider>
      </div>
      <FuseAnimate animation="transition.slideRightIn" delay={300}>
        <Button.Group>
          <Button type="primary">
            <Link to={`/survey-by-institution/add/${props.category}`}>
              थप्नुहोस
            </Link>
          </Button>

          <Button type="primary">
            <Link to={`/survey-by-institution/report/${props.category}`}>
              report
            </Link>
          </Button>
        </Button.Group>
      </FuseAnimate>
    </div>
  );
}

export default ProductsHeader;
