import Club from "./Club";
import Institute from "./Institute";
import School from "./School";
import TourismPlace from "./TourismPlace";
import ProdEducation from "./ProdEducation";
import College from "./College";
import Health from "./Health";
import HealthPost from "./HealthPost";
import GrazingField from "./GrazingField";
import Forest from "./Forest";
import Nikunja from "./Nikunja";
import AnimalFarm from "./AnimalFarm";
import Animal from "./Animal";
import Police from "./Police";
import Temple from "./Temple";
import Bank from "./Bank";
import CommunityBuilding from "./CommunityBuilding";
import Jadibuti from "./Jadibuti";
import RoadNetwork from "./RoadNetwork";
import CableCar from "./CableCar";
import Park from "./Park";
import Tuin from "./Tuin";
import Bridge from "./Bridge";
import MainRiver from "./MainRiver";
import Electricity from "./Electricity";
import Tower from "./Tower";
import PublicToilet from "./PublicToilet";
import FreeWifi from "./FreeWifi";
import Mines from "./Mines";
import TransmissionLine from "./TransmissionLine";
import Quarter from "./Quarter";
import FmStation from "./FmStation";
import Newspaper from "./Newspaper";
import CableNetwork from "./CableNetwork";
import BusTerminal from "./BusTerminal";
import ServiceCenter from "./ServiceCenter";
import Industry from "./Industry";
import MarketPlace from "./MarketPlace";
import Ground from "./Ground";
import NaturalDisaster from "./NaturalDisaster";
import DisasterInformation from "./DisasterInformation";
import Hotels from "./Hotels";
import Festival from "./Festival";
import VillageCouncilFamily from "./VillageCouncilFamily";
import VillageCouncilStaff from "./VillageCouncilStaff";
import TechnicalEducation from "./TechnicalEducation";

import Business from "@material-ui/icons/Business";
import Group from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import Place from "@material-ui/icons/Place";
import Star from "@material-ui/icons/Star";

// import Item from "./Item";
// import Project from "./Project";

export const ngo = {
  nepali: "सरकारी संस्था / गैर सरकारी संस्था",
  english: "",
  route: "institution",
  Component: Institute,
  count: 2,
};
export const police = {
  nepali: "सुरक्षा निकाय विवरण",
  english: "",
  route: "police",
  count: 2,
  Component: Police,
};
export const bank = {
  nepali: "बैंक तथा वित्तीय संस्था विवरण",
  english: "",
  route: "bank",
  count: 2,
  Component: Bank,
};
export const communityHall = {
  nepali: "सामुदायिक भवन",
  english: "",
  route: "communityBuilding",
  count: 2,
  Component: CommunityBuilding,
};
export const quarter = {
  nepali: "सरकारी कार्यालय तथा कर्मचारीहरुको बासस्थान",
  english: "",
  route: "quarter",
  count: 2,
  Component: Quarter,
};
export const club = {
  nepali: "क्लबहरु",
  english: "",
  route: "club",
  count: 2,
  Component: Club,
};

export const school = {
  nepali: "स्कुल",
  english: "",
  route: "school",
  count: 2,
  Component: School,
};
export const college = {
  nepali: "कलेज",
  english: "",
  route: "college",
  count: 2,
  Component: College,
};
export const technicalEducation = {
  nepali: "प्राविधिक शिक्षा",
  english: "",
  route: "technicalEducation",
  count: 2,
  Component: TechnicalEducation,
};

// export const trainningInstitute = {
// 	nepali: "तालिम केन्द्र",
// english: "",
// 	route: "trainningInstitute",
// 	count: 2,
// 	Component: TrainningInstitute
// };

// export const prodEducation = {
// 	nepali: "प्रोड्शिक्षा",
// english: "",
// 	route: "prodEducation",
// 	count: 2,
// 	Component: ProdEducation
// };

export const touristPlace = {
  nepali: "पर्यटकीय क्षेत्र",
  english: "",
  route: "tourismPlace",
  count: 2,
  Component: TourismPlace,
};

export const park = {
  nepali: "पार्क",
  english: "",
  route: "park",
  count: 2,
  Component: Park,
};

export const hotel = {
  nepali: "होटल/रेस्टुरेन्ट/होमस्टे सम्बन्धि विवरण",
  english: "",
  route: "hotel",
  count: 2,
  Component: Hotels,
};

export const mine = {
  nepali: "खानी",
  english: "",
  route: "mine",
  count: 2,
  Component: Mines,
};
export const forest = {
  nepali: "सामुदायिक वन",
  english: "",
  route: "forest",
  count: 2,
  Component: Forest,
};
export const nikunja = {
  nepali: "राष्ट्रिय निकुञ्ज, आरक्षण र मध्यवर्ति क्षेत्र",
  english: "",
  route: "nikunja",
  count: 2,
  Component: Nikunja,
};
export const bridge = {
  nepali: "पुल",
  english: "",
  route: "bridge",
  count: 2,
  Component: Bridge,
};
export const ground = {
  nepali: "मैदान",
  english: "",
  route: "ground",
  count: 2,
  Component: Ground,
};
export const market = {
  nepali: "हाट बजार मार्केट",
  english: "",
  route: "market",
  count: 2,
  Component: MarketPlace,
};
export const flora = {
  nepali: "जडीबुटी तथा वनस्पती",
  english: "",
  route: "herbs",
  count: 2,
  Component: Jadibuti,
};
export const cableCar = {
  nepali: "केबलकार",
  english: "",
  route: "cableCar",
  count: 2,
  Component: CableCar,
};
export const tuin = {
  nepali: "तुइन भएको खोला/नदी",
  english: "",
  route: "tuin",
  count: 2,
  Component: Tuin,
};
export const mainRiver = {
  nepali: "प्रमुख खोला/नदी",
  english: "",
  route: "mainRiver",
  count: 2,
  Component: MainRiver,
};

export const communication = {
  nepali: "संचार टावर",
  english: "",
  route: "tower",
  count: 2,
  Component: Tower,
};

export const fm = {
  nepali: "एफ.एम",
  english: "",
  route: "fmStation",
  count: 2,
  Component: FmStation,
};

export const newspaper = {
  nepali: "पत्र पत्रिका",
  english: "",
  route: "newspaper",
  count: 2,
  Component: Newspaper,
};
export const cableNetwork = {
  nepali: "केवल नेटवर्क",
  english: "",
  route: "cableNetwork",
  count: 2,
  Component: CableNetwork,
};
export const temple = {
  nepali: "मठ मन्दिर",
  english: "",
  route: "temple",
  count: 2,
  Component: Temple,
};
export const mainBusiness = {
  nepali: "उद्योग तथा कलकारखाना",
  english: "",
  route: "mainBusiness",
  count: 2,
  Component: Industry,
};
export const festival = {
  nepali: "प्रमुख मेला, चाडपर्व तथा जात्राहरु",
  english: "",
  route: "festival",
  count: 2,
  Component: Festival,
};

export const busPark = {
  nepali: "मुख्य बस टर्मिनल",
  english: "",
  route: "busPark",
  count: 2,
  Component: BusTerminal,
};
export const publicToilet = {
  nepali: "सार्वजनिक शौचालय",
  english: "",
  route: "publicToilet",
  count: 2,
  Component: PublicToilet,
};
export const roadNetwork = {
  nepali: "सडक सञ्जाल",
  english: "",
  route: "roadNetwork",
  count: 2,
  Component: RoadNetwork,
};
export const freeWifi = {
  nepali: "निशुल्क वाइफाइ उपलब्ध भएका स्थान",
  english: "",
  route: "freeWifi",
  count: 2,
  Component: FreeWifi,
};

export const hospital = {
  nepali: "अस्पताल",
  english: "",
  route: "hospital",
  count: 2,
  Component: Health,
};
export const healthPost = {
  nepali: "स्वास्थ्य चौकी",
  english: "",
  route: "healthPost",
  count: 2,
  Component: HealthPost,
};
// export const animalVeterinary = {
// 	nepali: "पशु (भेटेनरी)/उपचार केन्द्र",
// 	english: "",
// 	route: "animalVeterinary",
// 	count: 2,
// 	Component: Health
// };
export const disasterInformation = {
  nepali: "विपद घटनाहरुमा क्षति (विगत ३ वर्ष)",
  english: "",
  route: "disasterInformation",
  count: 2,
  Component: DisasterInformation,
};
export const naturalDisaster = {
  nepali: "वाढी, पहिरोको जोखिम क्षेत्र",
  english: "",
  route: "naturalDisaster",
  count: 2,
  Component: NaturalDisaster,
};
export const animal = {
  nepali: "जनावर तथा चराचुरुङ्गीहरुको बिबरण",
  english: "",
  route: "animal",
  count: 2,
  Component: Animal,
};
export const grazingField = {
  nepali: "चरण क्षेत्र",
  english: "",
  route: "grazingField",
  count: 2,
  Component: GrazingField,
};
export const animalFarm = {
  nepali: "कृषि तथा पशु फार्म",
  english: "",
  route: "animalFarm",
  count: 2,
  Component: AnimalFarm,
};
export const serviceCenter = {
  nepali: "मुख्य मेकानिकल वर्कसप",
  english: "",
  route: "serviceCenter",
  count: 2,
  Component: ServiceCenter,
};
export const villageCouncilFamily = {
  nepali: "परिवारको विवरण",
  english: "",
  route: "rmFamily",
  count: 2,
  Component: VillageCouncilFamily,
};
export const villageCounilStaff = {
  nepali: "कर्मचारीहरुको विवरण",
  english: "",
  route: "rmStaff",
  count: 2,
  Component: VillageCouncilStaff,
};
export const powerHouse = {
  nepali: "जलविद्युत योजना",
  english: "",
  route: "electricity",
  count: 2,
  Component: Electricity,
};
export const transmissionLine = {
  nepali: "विद्युतीय ट्रान्समिसन लाईन",
  english: "",
  route: "transmissionLine",
  count: 2,
  Component: TransmissionLine,
};

// can rearrange list from here
export const instituteRoute = [
  {
    title: { english: "1. Education", nepali: "१. शिक्षा" },
    child: [
      school,
      college,
      technicalEducation,
      // trainningInstitute,
      // prodEducation
    ],
    Icon: SchoolIcon,
  },
  {
    title: { english: "2. Health Related", nepali: "२. स्वास्थ्य सम्बन्धी" },
    // child: [hospital, healthPost, animalVeterinary],
    child: [hospital, healthPost],
    Icon: Star,
  },
  {
    title: {
      english: "3. Forest and animal area",
      nepali: "३. वन तथा बन्यजन्तु आरक्षण क्षेत्र",
    },
    child: [nikunja, forest, animal, grazingField, flora],
  },
  {
    title: {
      english: "4. Animal Livestock",
      nepali: "४. कृषि तथा पशू फार्म",
    },
    child: [animalFarm],
    Icon: Star,
  },
  {
    title: { english: "5. Policemen", nepali: "५. सुरक्षा निकाय सम्बन्धि" },
    child: [police],
  },
  {
    title: { english: "6. Club", nepali: "६. क्लब तथा सामुहिक विवरण" },
    child: [club],
    Icon: Group,
  },
  {
    title: {
      english: "7. Temple",
      nepali: "७. धार्मिक स्थल तथा चार्डपर्व सम्बन्धि",
    },
    child: [temple, festival],
    Icon: Group,
  },
  {
    title: { english: "8. Tourism Place", nepali: "८. पर्यटन सम्बन्धी" },
    child: [touristPlace, hotel],
    Icon: Place,
  },
  {
    title: {
      english: "9. Institute",
      nepali: "९. संस्थागत विवरण",
    },
    child: [ngo],
    Icon: Business,
  },
  {
    title: {
      english: "10. Resources",
      nepali: "१०. भौतिक पूर्बाधार स्थिति विवरण",
    },
    child: [
      cableCar,
      tuin,
      bridge,
      powerHouse,
      transmissionLine,
      communication,
      market,
      mainRiver,
      fm,
      newspaper,
      cableNetwork,
    ],
    Icon: Star,
  },
  {
    title: { english: "11. Public Place", nepali: "११. सार्वजनिक स्थल" },
    child: [park, publicToilet, freeWifi],
    Icon: Star,
  },
  {
    title: { english: "12. Bank", nepali: "१२. बैंक तथा वित्तीय संस्था" },
    child: [bank],
    Icon: Star,
  },
  {
    title: { english: "13. Road Network", nepali: "१३. यातायात" },
    child: [busPark, roadNetwork],
    Icon: Star,
  },
  {
    title: { english: "14. GROUND", nepali: "१४. युवा तथा खेलकुद" },
    child: [ground],
    Icon: Star,
  },
  {
    title: {
      english: "15. Natural Disaster",
      nepali: "१५. विपद जोखिम तथा ब्यबस्थापन",
    },
    child: [disasterInformation, naturalDisaster],
    Icon: Star,
  },
  {
    title: {
      english: "16. Community Building",
      nepali: "१६. सामुदायिक तथा सरकारी भवन",
    },
    child: [communityHall, quarter],
    Icon: Star,
  },
  {
    title: {
      english: "17. Project",
      nepali: "१७. गाउँपालिकाको कर्मचारी तथा परिवार विवरण",
    },
    child: [villageCouncilFamily, villageCounilStaff],
    Icon: Star,
  },
  {
    title: {
      english: "18. Business",
      nepali: "१८. व्यापार तथा व्यवसाय सम्बन्धी",
    },
    child: [mainBusiness],
    Icon: Star,
  },
  {
    title: { english: "19. Others", nepali: "१९. अन्य" },
    child: [serviceCenter, mine],
    Icon: Star,
  },
]; //survey ko card display garauda
