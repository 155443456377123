import { Grid, IconButton, Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
  advanceSearchFamily,
  advanceSearchHouse,
  clearAdvancedSearchData
} from "../../../../../actions/searchAction";
import isEmpty from "../../../../../utils/validation/is-empty";
import {
  searchByNameTableHeader,
  advanceHouseSearchTableHeader,
  selectedLanguage
} from "../../../../../variable/global";
import FamilyTable from "./FamilyTable";
import HouseTable from "./HouseTable";
import SearchOption from "./SearchOption";
import {
  rangeValue,
  autoSuggetionString,
  isInteger,
  HouseSearchIndex,
  mapData,
  isBoolean,
  booleanAttribute,
  nestedArray
} from "../../../../../variable/advanceSearch";
import Search from "antd/lib/transfer/search";
import { Button } from "antd";
import MySnackbarContentWrapper from "../../../../common/MySnackbarContentWrapper";
import HouseMap from "./HouseMap";
import { gaupalikaByward } from "../../../../../variable/villageGeoJson";
import ReactToPrint from "../../../../common/frequent/print/ReactToPrint.jsx";
import { Fab } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 20,
      snack: false,
      snackVariant: "",
      snackMessage: "",
      option: {
        age: [10, 30]
      },
      selection: [],
      selectedOption: "",
      isHouseSearchTable: false,
      query: {},
      beforePrint: false,
      component: null
    };
  }

  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState(
      { beforePrint: true, component: this.printRef },
      () => {
        return this.state.component;
      }
    );
  };

  componentDidMount() {
    this.props.clearAdvancedSearchData();
  }

  //  hit the api
  fetchData = () => {
    console.log("fetchdata query", this.state.query);
    if (this.state.isHouseSearchTable)
      this.props.advanceSearchHouse(this.state.query);
    else this.props.advanceSearchFamily(this.state.query);
  };

  // notification close garda
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snack: false });
  };

  //  notification fire
  setSnack = (msg, variant) => {
    this.setState({
      snack: true,
      snackVariant: variant,
      snackMessage: msg
    });
  };

  // paginatio action ko page change garda
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  // pagination ko action ko rows per page change huda
  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  // chip click huda
  onOptionSelected = value => {
    const selectedOption = autoSuggetionString.filter(
      each => each[selectedLanguage] === value
    )[0].value;
    this.setState({ selectedOption });
  };

  // auto suggestion ma value change huda
  handleSelectChange = selection => {
    var selectedOption = "";
    if (selection.length > 0) {
      selectedOption = selection[selection.length - 1].value;
    }
    this.setState({ selection, selectedOption });
  };

  // custom function for state change

  // orQueryBuilder
  orQueryBuilder = value => {
    const option = this.state.option;
    const arr = option[value];
    var queryBuild = null;
    if (arr.length > 1) {
      queryBuild = {};
      queryBuild.$or = [];
      arr.map(each => {
        queryBuild.$or.push({ [value]: each });
      });

      return queryBuild;
    } else return { [value]: arr };
  };

  // rangeQueryBuilder
  rangeQueryBuilder = value => {
    const option = this.state.option;
    const arr = option[value];
    var queryBuild = null;
    if (arr.length > 1) {
      queryBuild = {
        $gte: arr[0] || 0,
        $lte: arr[1] || 100
      };
      return queryBuild;
    } else return arr[0];
  };

  // orQueryBuilder
  oRqueryBuilder = value => {
    const option = this.state.option;
    let arr = option[value];
    if (this.shallWeParseInt(value)) arr = arr.map(each => parseInt(each));
    var queryBuild = null;
    if (arr.length >= 1) {
      queryBuild = {};
      queryBuild.$in = [];
      arr.map(each => {
        queryBuild.$in.push(each);
      });

      return queryBuild;
    } else return arr[0];
  };

  // andQueryBuilder
  andQueryBuilder = () => {};

  checkIfAnyHouseSearchKeyExist = value => {
    if (HouseSearchIndex.includes(value)) {
      this.setState({
        isHouseSearchTable: true
      });
    }
  };

  clearSelection = () => {
    this.setState({ selectedOption: "" });
  };

  clearQuery = () => {
    this.setState({
      isHouseSearchTable: false,
      query: {
        house: {},
        family: {},
        education: {},
        occupation: {},
        disable: {},
        abroad: {},
        death: {},
        insurance: {},
        houseFacility: {},
        rent: {},
        land: {},
        agriculture: {},
        agricultureDetail: {},
        liveStock: {},
        beeFishSilk: {},
        economics: {},
        skill: {},
        business: {},
        womenChild: {},
        welfare: {}
      }
    });
  };

  isThisValueBoolean = value => {
    return isBoolean.includes(value);
  };

  // if value need to parse in isInteger
  shallWeParseInt = value => {
    return isInteger.includes(value);
  };

  isNestedValue = value => {
    return nestedArray.includes(value);
  };

  // build the query after onChange
  queryBuilder = async () => {
    await this.clearQuery();

    const query = this.state.query; //query for mongodb
    const option = this.state.option; //option checked by user for search result [male,female]
    const selection = this.state.selection; //search ma kun kun attribute: [gender,religion]

    var buildQuery = {};

    if (selection.length > 0) {
      //yedi user le euta option pani select gareko xa vane do further, like religion,gender
      selection.map(({ value }) => {
        // value ma gender,religion hunxa vane selection ma nepali english and value hunxa
        this.checkIfAnyHouseSearchKeyExist(value); //yedi house ko table dekhauna parne attributes haru user le select gareko xa ki?
        const searchKey = this.findKey(value); // gender search garda family model ma parxa
        var nestedOption = {}; //gender ko complete query build ie. $in , $or
        if (this.isNestedValue(value)) {
          const qyeryORForThisValue = this.oRqueryBuilder(value);
          const valueCustom = value.replace("_", "."); //agricultureDetail_title lai "agricultureDetail.title" banaune
          nestedOption = {
            [valueCustom]: qyeryORForThisValue //should be gender: male
          };
        } else if (this.isThisValueBoolean(value)) {
          console.log(value, option[value]);
          const isTrueOrFalse = option[value] == booleanAttribute[value];
          nestedOption = {
            [value]: isTrueOrFalse //should be key: true
          };
        } else if (option && option[value] && Array.isArray(option[value])) {
          //edit value ko ooption ma aaray xa vane matra
          if (rangeValue.includes(value)) {
            const qyeryORForThisValue = this.rangeQueryBuilder(value);
            nestedOption = {
              [value]: qyeryORForThisValue //should be gender: male
            };
          } else {
            const qyeryORForThisValue = this.oRqueryBuilder(value);
            nestedOption = {
              [value]: qyeryORForThisValue //should be gender: male
            };
          }
        } else if (option && option[value]) {
          nestedOption = {
            [value]: option[value] //should be gender: male
          };
        }

        const other = this.state.query[searchKey];
        buildQuery[searchKey] = Object.assign(other, nestedOption);
      });

      this.setState(
        state => ({
          ...state,
          query: {
            ...query,
            ...buildQuery
          }
        }),
        () => {
          this.fetchData();
        }
      );
    } else {
      //  notification fire
      this.setSnack("Please select option", "warning");
    }
  };

  // Finding the object key with the value inside the array?
  findKey = attr => {
    return Object.keys(mapData).find(k => mapData[k].includes(attr));
  };

  // search result click garda
  handleSearch = () => {
    // event.preventDefault();
    // const option = this.state.option;
    this.props.clearAdvancedSearchData();
    this.clearSelection();
    this.queryBuilder();
    // if (!isEmpty(option)) this.queryBuilder();
    // else {
    // 	this.fetchData();
    // }
  };

  //  option vitra ko state change garda i.e option like checkbox change garda
  handleChange = (name, value) => {
    const data = name.split(".");
    const wc = this.state[data[0]];
    const assign = { [data[1]]: value };
    this.setState(state => ({
      ...state,
      [data[0]]: {
        ...wc,
        ...assign
      }
    }));
  };

  getGPSCoordinte = searchData => {
    if (isEmpty(searchData)) return false;
    else if (this.state.isHouseSearchTable)
      return searchData.map(each => [each.gps.latitude, each.gps.longitude]);
    else
      return searchData.map(each => [
        each.house.gps.latitude,
        each.house.gps.longitude
      ]);
  };

  handleMinAgeChange = value => {
    const age = this.state.option.age;
    const option = this.state.option;
    const max = age[1] || 120;
    const assign = { age: [value, max] };
    this.setState(state => ({
      ...state,
      option: {
        ...option,
        ...assign
      }
    }));
    this.setState({});
  };

  // render
  render() {
    const { classes, loading, searchData } = this.props;
    const { rowsPerPage, page, selection } = this.state;
    // const houseCoordinate =
    // 	(selection.length > 0 && this.getGPSCoordinte(searchData)) || [];
    return (
      <Grid container direction="column" className={classes.root}>
        <Grid item container direction="column">
          <SearchOption
            handleMinAgeChange={this.handleMinAgeChange}
            handleSearch={this.handleSearch}
            handleChange={this.handleChange}
            state={this.state.option}
            selection={this.state.selection}
            handleSelectChange={this.handleSelectChange}
            selectedOption={this.state.selectedOption}
            onOptionSelected={this.onOptionSelected}
          />
        </Grid>
        <Grid container justify="flex-end">
          <Grid item>
            {searchData && searchData.length !== 0 && (
              <ReactToPrint
                style={{ marginTop: 200 }}
                onBeforePrint={this.handleBeforePrint}
                onAfterPrint={this.handleAfterPrint}
                trigger={() => (
                  <Fab
                    color="secondary"
                    variant="extended"
                    aria-label="Delete"
                    className={classes.fab}
                  >
                    <PrintIcon className={classes.printIcon} />
                  </Fab>
                )}
                data={this.state.component}
                content={this.getContent}
              />
            )}
          </Grid>
        </Grid>

        {loading ? (
          <Grid
            container
            direction="row"
            justify="center"
            style={{ height: "50vh" }}
            alignItems="center"
          >
            <Grid item>
              <ClipLoader
                sizeUnit={"px"}
                size={84}
                color={"#00ACC1"}
                loading={loading}
              />
            </Grid>
            <Grid item>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                open={this.state.snack}
                autoHideDuration={4000}
                onClose={this.handleClose}
              >
                <MySnackbarContentWrapper
                  onClose={this.handleClose}
                  variant={this.state.snackVariant}
                  message={this.state.snackMessage}
                />
              </Snackbar>
            </Grid>
          </Grid>
        ) : (
          searchData &&
          searchData.length !== 0 &&
          (this.props.isHouse ? (
            <Grid
              ref={el => (this.printRef = el)}
              container
              direction="container"
              style={{ zIndex: 1, marginTop: 20 }}
              justify="center"
              alignItems="flex-start"
            >
              <HouseTable
                rows={searchData}
                rowsPerPage={rowsPerPage}
                page={page}
                title="घरधुरी खोजि"
                subtitle="बिस्तृत खोजि"
                tableHeader={advanceHouseSearchTableHeader}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChangePage={this.handleChangePage}
              />
              {/* {houseCoordinate && (
								<HouseMap
									GeoJSONData={gaupalikaByward}
									cordinates={houseCoordinate}
									zoom={12}
									// addMarker={this.props.addMarker}
								>
									<p>
										स्थान प्राप्त गर्न<em>मार्कर</em> पिन गर्नुहोस
									</p>
								</HouseMap>
							)} */}
            </Grid>
          ) : (
            <Grid
              container
              style={{ zIndex: 1, marginTop: 20 }}
              justify="center"
              alignItems="flex-start"
            >
              <FamilyTable
                rows={searchData}
                rowsPerPage={rowsPerPage}
                page={page}
                title="परिवार खोजि"
                history={this.props.history}
                subtitle="बिस्तृत खोजि"
                tableHeader={searchByNameTableHeader}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleChangePage={this.handleChangePage}
              />

              {/* {houseCoordinate && (
								<HouseMap
									GeoJSONData={gaupalikaByward}
									cordinates={houseCoordinate}
									zoom={12}
									// addMarker={this.props.addMarker}
								>
									<p>
										स्थान प्राप्त गर्न<em>मार्कर</em> पिन गर्नुहोस
									</p>
								</HouseMap>
							)} */}

              {/* <FamilyTable
								rows={searchData}
								rowsPerPage={rowsPerPage}
								page={page}
								title="परिवार खोजि"
								subtitle="बिस्तृत खोजि"
								tableHeader={searchByNameTableHeader}
								handleChangeRowsPerPage={this.handleChangeRowsPerPage}
								handleChangePage={this.handleChangePage}
							/> */}
            </Grid>
          ))
        )}
        <br />
      </Grid>
    );
  }
}

const styles = theme => ({
  container: {},
  root: {
    marginTop: theme.spacing.unit * 5,
    // padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  homeIcon: {
    marginLeft: 5,
    marginRight: 5,
    color: "#673ab7"
  }
});

Index.propTypes = {
  classes: PropTypes.object.isRequired,
  searchData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isHouse: PropTypes.bool
};
const mapStateToProps = ({ search: { data, advanceLoading, isHouse } }) => ({
  searchData: data && (data.familyResultData || data.houseResultData),
  loading: advanceLoading,
  isHouse
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    advanceSearchFamily,
    advanceSearchHouse,
    clearAdvancedSearchData
  })(withRouter(Index))
);
