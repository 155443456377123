import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { instituteRoute } from "./settings";
import { selectedLanguage } from "../../../variable/global";
class AddInstitutionSurvey extends Component {
	state = {
		config: null
	};

	componentDidUpdate(prevProps) {
		const category = this.props.match.params.category;
		if (prevProps.match.params.category !== category) {
			this.fetchConfig();
		}
	}

	componentDidMount() {
		this.fetchConfig();
	}

	fetchConfig() {
		const category = this.props.match.params.category;

		let config;
		instituteRoute.forEach(e =>
			e.child.forEach(o => {
				if (o.route === category) config = o;
			})
		);
		this.setState({ config });
	}
	render() {
		const { config } = this.state;
		if (!config) return <p>Page Not Found</p>;
		const mode = this.props.match.params.mode;
		return (
			<config.Component
				isUpdate={mode === "edit"}
				title={config[selectedLanguage]}
				category={config.route}
			/>
		);
	}
}

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2
	}
});

export default withStyles(styles)(AddInstitutionSurvey);
