import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AddInstitutionSurvey from "./AddInstitutionSurvey";
// import EditInstitutionSurvey from "./EditInstitutionSurvey";
// import InstitutionSurvey from "./InstitutionSurvey";
import InstitutionSurvey from "./list/";
import ReportForm from "./reportForm/";
import PreviewForm from "./PreviewForm";
import Home from "./InstitutionForm";
class Index extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/survey-by-institution/show/:category"
						component={InstitutionSurvey}
					/>
					<Route
						path={`${this.props.match.url}/preview/:category/:id`}
						component={PreviewForm}
					/>
					<Route
						path={`${this.props.match.url}/report/:category/`}
						component={ReportForm}
					/>
					<Route
						path={`${this.props.match.url}/:mode/:category/:id`}
						component={AddInstitutionSurvey}
					/>
					<Route
						path={`${this.props.match.url}/:mode/:category`}
						component={AddInstitutionSurvey}
					/>

					{/* <Route
						path={`${this.props.match.url}/edit/:category/:id`}
						component={EditInstitutionSurvey}
					/> */}
					<Route exact path={`${this.props.match.url}`} component={Home} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(Index);
