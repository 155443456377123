import React from "react";
import { FusePageCarded } from "./component/";
import ProductsTable from "./ProductsTable";
import ProductsHeader from "./ProductsHeader";
import Axios from "axios";
import { message } from "antd";
import _ from "lodash";
class Professionals extends React.Component {
  state = {
    searchText: "",
    temp: [],
    data: [],
    title: [],
    header: "",
    selected: [],
    page: 0,
    rowsPerPage: 20,
    order: {
      direction: "asc",
      id: null,
    },
  };

  handleSearchText = (ev) => {
    if (ev == null) return;
    var { temp, title } = this.state;
    const searchText = ev.target.value || "";
    const data =
      searchText.length === 0
        ? temp
        : _.filter(
            temp,
            (item) =>
              title.findIndex((each) =>
                item[each.dataIndex]
                  ? item[each.dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  : false
              ) >= 0
          );
    this.setState({ searchText: ev.target.value, data: data });
  };

  //  handleRequestSort = (event, property) => {
  // 	const id = property;
  // 	let direction = "desc";

  // 	if (order.id === property && order.direction === direction) {
  // 		direction = "asc";
  // 	}

  // 	setOrder({
  // 		direction,
  // 		id
  // 	});
  // }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { category } = this.props.match.params;
    Axios.get(`/api/institute/${category}/all/table`).then((res) => {
      var { data, title, mapValue, header } = res.data;
      var columns = [];
      // columns.push({
      // 	title: "क्र.श",
      // 	dataIndex: "sn",
      // 	key: "sn",
      // 	render: each => <p>{each}</p>
      // });
      title.forEach((each) => {
        var props = {
          sorter: (a, b) =>
            +(a[each.dataIndex] > b[each.dataIndex]) ||
            -(a[each.dataIndex] < b[each.dataIndex]),
          render: (data) => <p>{data}</p>,
        };

        if (mapValue[each.dataIndex]) {
          props.filters = mapValue[each.dataIndex].map((mapV) => ({
            text: mapV.nepali,
            value: mapV.nepali,
          }));
          props.onFilter = (value, record) => {
            return record[each.dataIndex]
              ? record[each.dataIndex] == value
              : false;
          };
        }
        each.title = <span className="font-semibold">{each.title}</span>;
        columns.push({
          ...each,
          ...props,
        });
      });
      // columns.push({
      // 	title: "क्रियाकलाप",
      // 	render: () => <a>action</a>
      // });
      const dataSource = data.map((each, sn) => {
        const body = {};
        Object.keys(each).forEach((key) => {
          if (typeof each[key] === "boolean") {
            body[key] = each[key] ? "छ" : "छैन";
          } else if (mapValue[key]) {
            const one = mapValue[key].find((v) => v.value === each[key]);
            if (one) body[key] = one["nepali"];
            else body[key] = each[key];
          } else body[key] = each[key];
        });
        return body;
      });

      this.setState({
        data: dataSource,
        temp: dataSource,
        title: columns,
        header: header,
      });
    });
  };
  handleDelete = (id) => {
    const { category } = this.props.match.params;
    Axios.delete(`/api/institute/${category}/delete/${id}`).then((res) => {
      if (res.data.success) {
        this.fetchData();
        message.success("Data successfully deleted");
      }
    });
  };

  render() {
    const { data, title, header, searchText, order } = this.state;
    const { category } = this.props.match.params;

    return (
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <ProductsHeader
            header={header}
            category={category}
            searchText={searchText}
            handleSearchText={this.handleSearchText}
          />
        }
        content={
          <ProductsTable
            columns={title}
            data={data}
            category={category}
            handleDelete={this.handleDelete}
          />
        }
        innerScroll
      />
    );
  }
}

export default Professionals;
