import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core";
import AlertDialog from "../../common/frequent/dialog/AlertDialog";
import { green, lightBlue, red } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import classnames from "classnames";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import isEmpty from "../../../utils/validation/is-empty";
import React, { Component } from "react";
import FullScreenDialog from "../../common/frequent/dialog/FullScreen";
import TablePaginationActionsWrapped from "../search/death/TablePaginationActions";
import ClipLoader from "react-spinners/ClipLoader";
import GetHouseDetail from "../search/advancedHouseSearch/houseNumber/GetHouseDetail";
import {
  clearHouseDetail,
  getHouseByHouseId,
} from "../../../actions/searchAction";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Axios from "axios";
import TextField from "@material-ui/core/TextField";
class ListHouse extends Component {
  state = {
    currenthouseId: "",
    deleteDialog: false,
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  removeFilename = () => {
    const id = this.state.currenthouseId;

    Axios.delete(`/api/house/delete/${id}`)
      .then((res) => {
        if (res.data.success) {
          this.handleClose();
          this.props.houselist();
        }
      })
      .catch((err) => {});
  };

  handleDeleteOpen = (id) => (event) => {
    this.setState({ deleteDialog: true, currenthouseId: id });
  };

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  filterData = () => {
    const {
      temp,
      houseNumber,
      contactNumber,
      wardNumber,
      ownerName,
    } = this.state;

    var houseNumberFilter = isEmpty(houseNumber)
      ? temp
      : temp
          .filter(({ house }) =>
            house.houseNumber.toString().startsWith(houseNumber)
          )
          .sort((a, b) => a.house.houseNumber - b.house.houseNumber);

    var houseWardNumberFilter = isEmpty(wardNumber)
      ? houseNumberFilter
      : houseNumberFilter.filter(({ house }) => house.wardNumber == wardNumber);
    var houseOwnerNameFilter = isEmpty(ownerName)
      ? houseWardNumberFilter
      : houseWardNumberFilter.filter((each) =>
          each.fullName.toString().includes(ownerName)
        );
    var houseOwnerContactFilter = isEmpty(contactNumber)
      ? houseOwnerNameFilter
      : houseOwnerNameFilter.filter(
          (each) =>
            each.contactNumber &&
            each.contactNumber.toString().includes(contactNumber)
        );
    this.setState({ houses: houseOwnerContactFilter });

    // this.setState({ houses: houseOwnerNameFilter });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.filterData()
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  clearFilter = () => {
    this.setState({
      sn: "",
      houseNumber: "",
      wardNumber: "",
      contactNumber: "",
    });
  };

  onHouseNumberClick = (hn) => {
    this.setState({ houseOpen: true, houseNumber: hn }, () => {
      this.props.getHouseByHouseId(hn);
    });
  };

  handleCloseHouse = (name) => {
    this.setState({ [name]: false, house: {} });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.house !== this.props.house) {
      this.setState({
        house: this.props.house,
      });
    }
    if (prevProps.houses !== this.props.houses) {
      this.setState({
        houses: this.props.houses,
        temp: this.props.houses,
      });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      houses: props.houses,
      temp: props.houses,
      page: 0,
      house: {},
      rowsPerPage: 100,
      houseOpen: false,
      sn: "",
      ownerName: "",
      houseNumber: "",
      wardNumber: "",
      contactNumber: "",
      citizenshipNumber: "",
    };
  }
  render() {
    const { classes } = this.props;
    const { houses } = this.state;
    const {
      page,
      rowsPerPage,
      sn,
      houseNumber,
      wardNumber,
      ownerName,
      citizenshipNumber,
      contactNumber,
    } = this.state;
    console.log("houses", houses);

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>क्र.स</Typography>
            </TableCell>
            <TableCell>
              <TextField
                id="outlined-houseNumber"
                label={"घर नम्बर"}
                className={classes.textFieldHN}
                name="houseNumber"
                value={houseNumber}
                type="number"
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </TableCell>
            <TableCell>
              <TextField
                id="outlined-wardNumber"
                label={"वडा न."}
                className={classes.textFieldWN}
                name="wardNumber"
                value={wardNumber}
                type="number"
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
                disabled={parseInt(sessionStorage.getItem("ward")) != 0}
              />
            </TableCell>
            <TableCell>
              <TextField
                id="outlined-ownerName"
                label={"घरमुलीको नाम"}
                className={classes.textFieldON}
                name="ownerName"
                value={ownerName}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </TableCell>
            <TableCell>
              <TextField
                id="outlined-contactNumber"
                label={"मोबाइल न."}
                className={classes.textFieldCN}
                name="contactNumber"
                value={contactNumber}
                type="number"
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </TableCell>
            <TableCell>Collector Code</TableCell>
            <TableCell>पुरुष</TableCell>
            <TableCell>महिला</TableCell>

            <TableCell>
              <Typography variant="h6">कृयाकलाप</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {houses &&
            houses
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((house, id) => {
                return (
                  <TableRow key={id}>
                    <TableCell
                      scope="row"
                      style={{ padding: "0px !important" }}
                    >
                      <Typography variant="body1">
                        {getNepaliNumber(page * rowsPerPage + (id + 1))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px !important" }}
                      align="left"
                    >
                      <Typography variant="body1">
                        {house &&
                          house.house &&
                          house.house.houseNumber &&
                          getNepaliNumber(house.house.houseNumber)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px !important" }}
                      align="left"
                    >
                      <Typography variant="body1">
                        {house &&
                          house.house &&
                          house.house.wardNumber &&
                          getNepaliNumber(house.house.wardNumber)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px !important" }}
                      align="left"
                    >
                      <Typography variant="body1">{house.fullName}</Typography>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px !important" }}
                      align="left"
                    >
                      <Typography variant="body1">
                        {house.contactNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>{house.house.dataCollectionCode}</TableCell>
                    <TableCell
                      style={{ padding: "0px !important" }}
                      align="left"
                    >
                      <Typography variant="body1">
                        {house &&
                          house.house &&
                          house.house.maleNumber &&
                          getNepaliNumber(house.house.maleNumber)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px !important" }}
                      align="left"
                    >
                      <Typography variant="body1">
                        {house &&
                          house.house &&
                          house.house.femaleNumber &&
                          getNepaliNumber(house.house.femaleNumber)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ padding: "0px !important" }}
                      align="left"
                    >
                      <VisibilityIcon
                        onClick={() =>
                          this.onHouseNumberClick(
                            house && house.house && house.house._id
                          )
                        }
                        className={classnames(classes.preview, classes.icon)}
                      />
                      {house.house && (
                        <Link to={`/survey-by-house/edit/${house.house._id}`}>
                          <Icon
                            className={classnames(classes.edit, classes.icon)}
                          />
                        </Link>
                      )}
                      <DeleteIcon
                        onClick={this.handleDeleteOpen(
                          house && house.house && house.house._id
                        )}
                        className={classnames(classes.delete, classes.icon)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
        {houses && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                colSpan={7}
                count={houses.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        )}
        {this.state.houseOpen && (
          <FullScreenDialog
            name="houseOpen"
            open={this.state.houseOpen}
            title={`House Detail `}
            handleClose={this.handleCloseHouse}
          >
            <Grid container spacing={24}>
              {this.props.load ? (
                <Grid
                  style={{ marginTop: "40vh" }}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <ClipLoader
                      sizeUnit={"px"}
                      size={84}
                      color={"#00ACC1"}
                      loading={this.props.load}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  {this.state.house && (
                    <Grid item xs={9}>
                      <GetHouseDetail
                        houseNumber={
                          this.state.house &&
                          this.state.house.houseDetails &&
                          this.state.house.houseDetails.houseNumber
                        }
                        house={this.state.house}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </FullScreenDialog>
        )}
        <AlertDialog
          open={this.state.deleteDialog}
          handleClose={this.handleClose}
          negButtonClick={this.handleClose}
          posButtonClick={this.removeFilename}
          title="Are you sure you want to delete the user ?"
          posButton="agree"
          negButton="disagree"
        />
      </Table>
    );
  }
}

const styles = (theme) => ({
  textFieldSN: {
    width: 75,
  },
  textFieldHN: {
    width: theme.spacing.unit * 12,
  },
  textFieldWN: { width: theme.spacing.unit * 12 },
  textFieldON: { width: theme.spacing.unit * 16 },
  textFieldCN: { width: theme.spacing.unit * 15 },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  fab: {
    float: "right",
  },
  icon: {
    margin: theme.spacing.unit * 1,
    cursor: "pointer",
  },
  preview: {
    color: lightBlue[500],
  },
  edit: {
    color: green[700],
  },
  delete: {
    color: red[500],
  },
});

const mapStateToProps = ({ search: { house, load } }) => ({
  house,
  load,
});
export default withStyles(styles)(
  connect(mapStateToProps, { clearHouseDetail, getHouseByHouseId })(ListHouse)
);
