import { Grid, MenuItem, Select, Typography } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { getAnalaysisHouseDetailChartData } from "../../../../actions/analysisAction";
import {
  gaupalikaWard,
  selectedLanguage,
  ward,
  wardAnalysis,
} from "../../../../variable/global";
import Can from "../../../casl/Can";
import FullScreenDialog from "../../../common/frequent/dialog/FullScreen";
import ChartDoghnot from "../pariwarikBibaran/charts/ChartDoghnot";
import BarGraph from "../pariwarikBibaran/charts/ChartList";
import ChartPie from "../pariwarikBibaran/charts/ChartPie";
import ChartPolar from "../pariwarikBibaran/charts/ChartPolar";
import DetailAnalysisTable from "./DetailAnalysisTable";
import "./gharbibaran.css";
class DetailAnalysis extends Component {
  constructor(props) {
    super(props);

    const role = parseInt(sessionStorage.getItem("role"));
    const ward = parseInt(sessionStorage.getItem("ward"));

    this.state = {
      wardData: ward,
      multipleFull: false,
      barFull: false,
      pieFull: false,
      doghnutFull: false,
      polarFull: false,
      selected: ward,
      role,
      ward,
    };
  }

  componentDidMount() {
    this.fetchChart();
  }

  onWardClick = (event) => {
    const wardData = event.target.value;
    this.setState({ wardData, selected: wardData });
  };

  fetchChart = () => {
    const option = this.props.match.params.option;
    console.log("option", option);
    this.props.getAnalaysisHouseDetailChartData(option);
  };

  handleClickOpen = (name) => {
    this.setState({ [name]: true });
  };

  handleClose = (name) => {
    this.setState({ [name]: false });
  };

  getBarChart = (chartData) => {
    if (this.props.chart) {
      // const single = this.props.chart[this.state.selected];
      return (
        <FullScreenDialog
          name="barFull"
          open={this.state.barFull}
          title="बार ग्राफ"
          handleClose={this.handleClose}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            {chartData && (
              <Grid item xs={8}>
                <BarGraph noTitle chartData={chartData} />
              </Grid>
            )}
          </Grid>
        </FullScreenDialog>
      );
    }
  };

  getPieChart = (chartData) => {
    if (this.props.chart) {
      // const single = this.props.chart[this.state.selected];
      return (
        <FullScreenDialog
          name="pieFull"
          open={this.state.pieFull}
          title="पाइ चार्ट"
          handleClose={this.handleClose}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            {chartData && (
              <Grid item xs={8}>
                <ChartPie noTitle chartData={chartData} />
              </Grid>
            )}
          </Grid>
        </FullScreenDialog>
      );
    }
  };

  getDoghnutChart = (chartData) => {
    if (this.props.chart) {
      // const single = this.props.chart[this.state.selected];
      return (
        <FullScreenDialog
          name="doghnutFull"
          open={this.state.doghnutFull}
          title="डोनट चार्ट"
          handleClose={this.handleClose}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            {chartData && (
              <Grid item xs={8}>
                <ChartDoghnot noTitle chartData={chartData} />
              </Grid>
            )}
          </Grid>
        </FullScreenDialog>
      );
    }
  };

  getPolarFull = (chartData) => {
    if (this.props.chart) {
      // const single = this.props.chart[this.state.selected];
      return (
        <FullScreenDialog
          name="polarFull"
          open={this.state.polarFull}
          title="पोलर चार्ट"
          handleClose={this.handleClose}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            {chartData && (
              <Grid item xs={8}>
                <ChartPolar noTitle chartData={chartData} />
              </Grid>
            )}
          </Grid>
        </FullScreenDialog>
      );
    }
  };

  getTitle = (ward, object) => {
    return object[ward][selectedLanguage];
  };

  render() {
    const { loading, classes, chart } = this.props;
    const { wardData, selected } = this.state;
    let selectedChart =
      chart && chart.ward.find((each) => parseInt(each.ward) === selected); //change this
    var chartData = false;
    if (chart && selectedChart)
      chartData = {
        title: "Static",
        label: selectedChart.data.map((each) => each.label),
        data: selectedChart.data.map((each) => each.value),
      };
    return (
      <div>
        {chart && chart[selected] && chart[selected] && (
          <Typography variant="h4" gutterBottom>
            {this.getTitle(wardData, wardAnalysis)} {chart.title}
          </Typography>
        )}
        <Can do="show" on={{ __type: "Home", ward: this.state.ward }}>
          {chart && chart.ward0 && (
            <Typography variant="h4" gutterBottom>
              {chart.ward0.title}
            </Typography>
          )}
          <Grid container>
            <Select
              className={classes.ward}
              name="ward"
              value={wardData}
              onChange={this.onWardClick}
            >
              <MenuItem value={0}>{gaupalikaWard[selectedLanguage]}</MenuItem>
              {ward.map((each) => (
                <MenuItem key={each.key} value={each.key}>
                  {each.ward}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Can>

        <Grid container>
          {loading ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <ClipLoader
                  sizeUnit={"px"}
                  size={84}
                  color={"#00ACC1"}
                  loading={loading}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column" className="frame-paper">
              {this.getBarChart(chartData)}
              {this.getPieChart(chartData)}
              {this.getDoghnutChart(chartData)}
              {this.getPolarFull(chartData)}

              <Grid container spacing={24}>
                {chart && chartData && (
                  <Grid item xs={6}>
                    <BarGraph
                      name="barFull"
                      isFullScreen={true}
                      handleClickOpen={this.handleClickOpen}
                      chartData={chartData}
                      label="बार ग्राफ"
                    />
                  </Grid>
                )}
                {chart && chartData && (
                  <Grid item xs={6}>
                    <ChartPie
                      name="pieFull"
                      isFullScreen={true}
                      handleClickOpen={this.handleClickOpen}
                      chartData={chartData}
                      label="पाइ चार्ट"
                    />
                  </Grid>
                )}
                {chart && chartData && (
                  <Grid item xs={6}>
                    <ChartDoghnot
                      name="doghnutFull"
                      isFullScreen={true}
                      handleClickOpen={this.handleClickOpen}
                      chartData={chartData}
                      label="डोनट चार्ट"
                    />
                  </Grid>
                )}
                {chart && chartData && (
                  <Grid item xs={6}>
                    <ChartPolar
                      name="polarFull"
                      isFullScreen={true}
                      handleClickOpen={this.handleClickOpen}
                      chartData={chartData}
                      label="पोलर चार्ट"
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className="print-break"
              >
                {chart && chart.reportTitle && (
                  <Grid item xs={12}>
                    <DetailAnalysisTable
                      ward={this.state.selected}
                      chartData={chart}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  fab: {
    position: "fixed",
    right: 60,
    "z-index": 100,
    backgroundColor: "#03a9f4",
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "#03a9f4",
    },
  },
});

DetailAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ analysis: { loading, chart } }) => ({
  loading,
  chart,
});
export default withStyles(styles)(
  connect(mapStateToProps, { getAnalaysisHouseDetailChartData })(DetailAnalysis)
);
